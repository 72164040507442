
@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Apply the animation to the specialClass */
  .specialClass {
    animation: pulse 1s;
    animation-iteration-count: 3;
  }
  